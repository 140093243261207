<template>
  <div id="gallery-list">
    <div
      class="flex flex-col md:flex-row justify-between md:items-center md:justify-start md:space-y-0 md:space-x-6"
    >
      <div class="flex flex-col md:flex-row items-center md:space-y-0 md:space-x-6">
        <filter-date-picker
          class="w-full md:w-auto mb-2 md:mb-0"
          @on-filter-date="filterDate($event)"
        />
        <filter-tags
          ref="tagSearch"
          class="w-full md:w-auto mb-3 md:mb-0 items-center"
          @on-filter-tags="filterTags($event)"
          :tagSearch="filtersTagFromModuleTag"
        />
        <filter-search
          class="w-full md:w-auto mb-2 md:mb-0"
          @on-filter-search="filterSearch($event)"
          :searchQueryProps="filtersFolder.name && filtersMedia.name"
          :searchQueryActive="searchQueryActive"
          @remove-search="removeSearch"
        />
        <div class="flex justify-end md:space-x-5 items-center">
          <select-all-folders
            v-if="folders.length > 0"
            class="md:w-auto mb-2 md:mb-0"
            :folders="folders"
          />
          <share-button :sidebarActive="sidebarActive" class="md:w-auto mb-2 ml-3 md:ml-0 md:mb-0" />
        </div>
      </div>
    </div>
    <new-folder />
    <vs-card
      ref="vsCardSidebar"
      id="vsCardSidebar"
      class="mt-5"
      :style="[sidebarActive ? { paddingRight: '270px !important' } : '']"
    >
      <div
        v-if="galleryIsEmpty"
        class="flex flex-col items-center text-center"
      >
        <span>{{ $t("gallery.folder.not_found.title") }}</span>
        <span v-if="$acl.check('galleryUpload')" class="font-medium">{{ $t("gallery.folder.not_found.text") }}</span>
      </div>
      <div
        v-else-if="galleryIsEmptySearch"
        class="flex flex-col items-center text-center mt-5"
      >
        <span>{{ $t("gallery.folder.not_found.search.title") }}</span>
      </div>
      <div
        v-if="($router.name = 'gallery')"
        class="folder-grid gap-3 mx-3 mt-5"
      >
        <div
          v-for="(folder, i) in folders"
          :key="folder[i]"
          class="folders mt-5 mb-32 cursor-pointer"
          @click="onClickFolder(folder.id)"
        >
          <input
            type="checkbox"
            ref="folderCheckbox"
            :checked="isAllFoldersSelected"
            :value="folder"
            @change="selectElement($event, folder)"
            @click.stop
            class="btn-selected-folder cursor-pointer"
          />
          <div class="inner-folder md:px-3 py-1 flex flex-wrap h-full">
            <div
              class="px-1 mt-2"
              v-for="media in folder.medias.slice(0, 6)"
              :key="media.id"
            >
              <img
                v-if="media.type === 'picture'"
                :src="`${media.thumbnailUrl}?bearer=${bearer}`"
                class="media-preview object-cover w-10 h-10 rounded-sm"
                alt=""
              />
            </div>
          </div>
          <div class="pt-4">
            <h4 class="description text-primary font-medium md:text-base">
              {{ folder.name | str_limit(15) }}
            </h4>
            <p class="description text-base pt-1">
              {{ folder.date | formatDate }}
            </p>
            <p>
              <span v-if="folder.countMedias > 0" class="description md:text-xs">
                Fichiers : {{ folder.countMedias }}
              </span>
              <span v-if="folder.childrenFolders.length > 0" class="description md:text-xs">
                | Dossiers : {{ folder.childrenFolders.length }}
              </span>
            </p>
          </div>
        </div>
        <sidebar @uncheckFolders="uncheckFolders()" @cancelSidebar="cancelSidebar()" />
      </div>
      <div class="mx-3" v-if="galleryBox.length > 0">
        <vs-divider v-if="!galleryIsEmptySearch" />
        <span class="font-medium">{{ $t("gallery.folder.found.media.search.title", { total: galleryBox.length }) }}</span>
        <silent-box
          class="gap-3 mt-5"
          :gallery="galleryBox"
          :lazy-loading="true"
        >
          <template v-slot:silentbox-item="{ silentboxItem }">
            <img
              v-if="silentboxItem.type === 'picture'"
              class="media"
              @click="redirectToFolder(silentboxItem.folder)"
              :src="`${silentboxItem.thumbnailUrl}?bearer=${bearer}`"
              :height="silentboxItem.thumbnailHeight"
              :width="silentboxItem.thumbnailWidth"
            />
            <video
              v-if="silentboxItem.type === 'video'"
              class="media"
              @click="redirectToFolder(silentboxItem.folder)"
              :src="silentboxItem.src || silentboxItem.file.src"
              :height="silentboxItem.thumbnailHeight"
              :width="silentboxItem.thumbnailWidth"
            />
            <p class="media-name">{{ silentboxItem.name | str_limit(21) }}</p>
          </template>
        </silent-box>
      </div>
    </vs-card>
    <div v-if="$router.name !== 'gallery'">
      <folder />
    </div>
  </div>
</template>

<script>
import Folder from './gallery-list/Folder'
import Sidebar from './gallery-list/Sidebar'
import FilterDatePicker from './gallery-list/FilterDatePicker'
import FilterTags from './gallery-list/FilterTags'
import NewFolder from './gallery-list/NewFolder'
import SelectAllFolders from './gallery-list/SelectAllFolders.vue'
import ShareButton from './gallery-list/ShareButton'
import FilterSearch from './gallery-list/FilterSearch'
import moduleMediaObjectMixin from '@/store/media-object/moduleMediaObjectMixin'
import moduleFolderMixin from '@/store/folder/moduleFolderMixin'
import moduleMediaMixin from '@/store/media/moduleMediaMixin'
import moduleTagsMixin from '@/store/tag/moduleTagMixin'
export default {
  mixins: [
    moduleTagsMixin,
    moduleMediaObjectMixin,
    moduleFolderMixin,
    moduleMediaMixin
  ],
  components: {
    Folder,
    NewFolder,
    FilterDatePicker,
    FilterTags,
    Sidebar,
    SelectAllFolders,
    ShareButton,
    FilterSearch
  },
  computed: {
    folders () {
      return this.$store.state.folder.folders
    },
    selectedFolders () {
      return this.$store.state.folder.selectedFolders
    },
    isAllFoldersSelected () {
      return this.$store.state.folder.isAllFoldersSelected
    },
    tags () {
      return this.$store.state.tag.tags
    },
    sidebarActive () {
      return this.$store.state.folder.totalSelectedFolders > 0
    },
    bearer () {
      return this.$store.getters['auth/accessToken']
    }
  },
  data () {
    return {
      galleryBox: [],
      searchMedias: [],
      payloadFilterRoot: {
        'exists[parentFolder]': false
      },
      searchQueryActive: false,
      galleryIsEmpty: false,
      galleryIsEmptySearch: false,
      filterIsActive: {
        date: false,
        tags: false,
        search: false
      },
      filtersFolder: {},
      filtersMedia: {},
      filtersTagFromModuleTag: {}
    }
  },
  methods: {
    filterDate (payload) {
      if (payload && payload.date) {
        this.filterIsActive.date = true
        this.filtersFolder = {...this.filtersFolder, date: payload.date}
        this.filtersMedia = {
          ...this.filtersMedia,
          'createdAt[after]': payload.date['strictly_after'],
          'createdAt[before]': payload.date['strictly_before']
        }
      } else {
        delete this.filtersFolder.date
        delete this.filtersMedia['createdAt[after]']
        delete this.filtersMedia['createdAt[before]']
        this.filterIsActive.date = false
      }
    },
    filterTags (payload) {
      if (payload && payload['tags.name'].length > 0) {
        this.filterIsActive.tags = true
        this.filtersFolder = {...this.filtersFolder, ['tags.name']: payload['tags.name']}
        this.filtersMedia = {...this.filtersMedia, ['tags.name']: payload['tags.name']}
      } else {
        delete this.filtersFolder['tags.name']
        delete this.filtersMedia['tags.name']
        this.filterIsActive.tags = false
      }
    },
    filterSearch (payload) {
      if (payload && payload['name']) {
        this.filterIsActive.search = true
        this.searchQueryActive = true
        this.filtersFolder = {...this.filtersFolder, name: payload.name}
        this.filtersMedia = {...this.filtersMedia, name: payload.name}
      } else {
        delete this.filtersFolder.name
        delete this.filtersMedia.name
        this.filterIsActive.search = false
        this.searchQueryActive = false
      }
    },
    async fetchFolders () {
      this.$vs.loading({
        container: '#gallery-list',
        scale: 0.6
      })
      const response = await this.$store.dispatch('folder/fetchFolders', this.payloadFilterRoot)
      response.data['hydra:totalItems'] === 0 ? this.galleryIsEmpty = true : this.galleryIsEmpty = false
      await this.$vs.loading.close('#gallery-list > .con-vs-loading')
    },
    async resetQuery () {
      this.$store.commit('folder/SET_LAST_FETCH_FOLDERS')
      this.searchMedias = []
      this.galleryBox = []
      this.galleryIsEmptySearch = false
      this.galleryIsEmpty = false
      this.searchQueryActive = false
    },
    async filterFolders () {
      this.$vs.loading({
        container: '#gallery-list',
        scale: 0.6
      })
      const response = await this.$store.dispatch('folder/fetchFolders', this.filtersFolder)
      response.data['hydra:totalItems'] === 0 ? this.galleryIsEmptySearch = true : this.galleryIsEmptySearch = false
      await this.$vs.loading.close('#gallery-list > .con-vs-loading')
    },
    async filterMedias () {
      this.$vs.loading({
        container: '#gallery-list',
        scale: 0.6
      })
      this.searchMedias = await this.$store.dispatch('media/searchMedias', this.filtersMedia)
      await this.refreshGalleryBox()
      await this.$vs.loading.close('#gallery-list > .con-vs-loading')
    },
    async refreshGalleryBox () {
      if (this.searchMedias.length > 0) {
        this.galleryBox = await this.searchMedias.map(media => {
          return {
            ...media,
            src: `${media.fileUrl}?bearer=${this.bearer}`,
            thumbnailWidth: '150px',
            thumbnailHeight: '150px',
            thumbnailUrl: media.thumbnailUrl,
            name: media.name,
            id: media.id,
            thumbnailFile: media.type === 'picture' ? media.thumbnailFile['@id'] : null
          }
        })
      } else {
        this.galleryBox = []
      }
    },
    async fetchTags () {
      await this.$store.dispatch('tag/fetchTags')
    },
    selectElement (e, element) {
      if (e.target.checked) {
        this.$store.commit('folder/ADD_ITEM_SELECTED_FOLDERS', element)
      } else {
        this.$store.commit('folder/REMOVE_ITEM_SELECTED_FOLDERS', element)
      }
    },
    redirectToFolder (folder) {
      const folderId = folder.split('/')[3]
      this.$router.push({
        name: 'folder',
        params: {
          folderId
        }
      })
    },
    onClickFolder (id) {
      this.$store.commit('folder/CLEAR_SELECTED_FOLDERS')
      this.$router.push({ name: 'folder', params: { folderId: id } })
    },
    uncheckFolders () {
      this.$refs.folderCheckbox.map(folder => {
        folder.checked = false
      })
    },
    cancelSidebar () {
      this.uncheckFolders()
      this.$store.commit('folder/SET_ALL_FOLDERS_SELECTED', false)
      this.$store.commit('folder/CLEAR_SELECTED_FOLDERS')
    },
    removeSearch () {
      delete this.filtersFolder.name
      delete this.filtersMedia.name
      this.filterIsActive.search = false
      this.searchQueryActive = false
    }
  },
  watch: {
    filtersFolder: {
      handler () {
        this.filterFolders()
      },
      deep: true
    },
    filtersMedia: {
      handler () {
        this.filterMedias()
      },
      deep: true
    },
    filterIsActive: {
      handler () {
        if (Object.values(this.filterIsActive).every((v) => v === false)) {
          this.resetQuery()
        }
      },
      deep: true
    }
  },
  beforeRouteEnter (to, _from, next) {
    if (to.params.tag) {
      next(vm => {
        vm.filtersTagFromModuleTag = to.params.tag
      })
    } else {
      next()
    }
  },
  async created () {
    await this.fetchTags()
    if (!this.filtersTagFromModuleTag.id) {
      this.fetchFolders()
    }
  }
}
</script>

<style scoped lang="scss">
.vs-textarea {
  font-family: Helvetica, Arial, sans-serif;
}
.edit-element .vs-sidebar.vs-sidebar-parent {
  padding: 0 14px;
}
.vs-sidebar--background {
  display: none;
}
.folder-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  justify-content: space-between;
}
.folders {
  width: 150px;
  max-width: 150px;
  height: 105px;
  position: relative;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.048), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(53, 53, 53, 0.356);
  border-radius: 0 6px 6px 6px;

  &:before {
    content: "";
    position: absolute;
    top: -14px;
    left: 0px;
    width: 50%;
    height: 12px;
    border-radius: 0 15px 0 0;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgba(109, 109, 109, 0.356);
  }

  .inner-folder {
    max-width: 150px;
  }
}
@media screen and (max-width: 768px) {
  .folder-grid {
    grid-template-columns: repeat(auto-fill, 60px) !important;
  }
  .folders {
    width: 60px;
    max-width: 60px;
    height: 50px;

    &:before {
      top: -7px;
      height: 5px;
    }

    .inner-folder {
      min-width: 50px;
    }
  }
  .description {
    font-size: 0.6rem !important;
  }
  .media-preview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 10px !important;
    height: 10px !important;
  }
}
</style>
